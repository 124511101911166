.card {
    /* display: flex; */
    align-items: center;
    background: linear-gradient(135deg, #809eab 0%, #455a64 100%);
    /* border-radius: 15px; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 40px;
    /* height: 80vh; */
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    /* overflow: hidden; */
    position: relative;
    perspective: 1000px;
    margin:25px;
}

.card:hover {
    transform: translateY(-12px) rotateY(2deg);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    background: linear-gradient(145deg, #809eab, #455a64);
}

.card_image {
    /* width: 70vh;
    height: 70vh; */
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;
    filter: brightness(90%);
    transition: transform 0.5s ease-in-out;
}

.card:hover .card_image {
    transform: scale(1.08);
    filter: brightness(100%);
    
}
.card_text {
    flex: 1;
    padding: 0 30px;
    color: #f5f5f5;
    z-index: 2;
    transform: translateZ(50px);
}

.card_text h3 {
    font-size: 2.2rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    transition: color 0.4s ease;
}

.card_text p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #e0e0e0;
}

.card_link_box {
    margin-top: 20px;
}

.card_button {
    padding: 14px 28px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.4s ease;
    position: relative;
    z-index: 2;
    transform: translateX(0);
}

.card_button:hover {
    background-color: #0056b3;
    transform: scale(1.12);
}

.card_left {
    flex-direction: row;
    padding: 30px;
    

}

.card_right {
    flex-direction: row-reverse;
    
    padding: 30px;
}
