.carousel {
    width: 100%;
    height: 400px;
    overflow: clip;
    position: relative;
}

.carousel_inner {
    display: flex;
    flex-wrap: nowrap;
    width: 200%;
    height: 100%;
    animation: scroll 20s linear infinite;
}

.image {
    height: 100%;
    width: 400px; 
    object-fit: cover;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
