.contactUs {
    display: flex;
    padding: 70px;
    justify-content: space-between;
    background-color: #090d4a;
    color:white;
    /* margin: 20px 0; */
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.social_media {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: x-large; 
}

.icon{
    margin: 10px;
    display: flex;
    display: flex;
    flex-direction: row;
}

.company {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
}

.company span {
    font-size: 50px;
    padding-top: 20px ;
}

.navigations {
    display: flex;
    flex-direction: column;
    color:#CCB0FF;
    gap:25px;
}

.navigations span {
    display: flex;
    justify-content: left;
}

.scroll_link {
    display: flex;
    padding: 5px;
    color: #CCB0FF;
    text-decoration: none;
    cursor: pointer; 
}

.scroll_link:hover {
    background-color: #fff;
    border-radius: 4px;
    color: black
}


.contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:10px;
}

.contact span{
    display: flex;
    align-items: center;
}

.contactItem {
    display: flex;
    align-items: center; 
}

.icon {
    margin-right: 15px; 
    font-size: 1.2rem; 
}

.footer {
    display: flex;
    justify-content: left;
    font-weight: 600;
}
